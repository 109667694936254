import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { adminLogin } from "../../../Redux/Actions/auth";
const Login = () => {
    const [Hide,setHide]=useState(false)
    const dispatch =useDispatch();
    const navigate=useNavigate();

    useEffect(() => {
      if (window.sessionStorage.getItem("token")){
        if (
          !window.sessionStorage.getItem("token")
        ) {
          window.sessionStorage.setItem("token");
        }
         navigate("/admin/user-management")
      }
    }, []);
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Password required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be 6 characters long";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit:async (values) => {
        try{
          await dispatch(adminLogin(values))
            // navigate("/user-management")
        }catch (error){
            console.log(error);
        }
    },
  });
  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-xl-8">
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="p-4 mt-auto">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end col --> */}
        <div className="col-xl-4">
          <div className="auth-full-page-content bg-orange p-md-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-1 mb-md-1">
                  <a href="#" className="d-block auth-logo">
                    <img
                      src={require("../../../assets/images/logo-login.png")}
                      alt=""
                      height="150"
                      className="auth-logo-dark"
                    />
                  </a>
                </div>
                <div className="my-auto">
                  <div>
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to Muslim Matcher.
                    </p>
                  </div>

                  <div className="mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <label for="username" className="form-label">
                          Username
                        </label>
                        <input
                        name="email"
                          onChange={formik.handleChange}
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Enter username"
                          value={formik.values.email}
                        />
                         {formik.errors.email ? (
                              <p style={{ color: "red" }}>
                                {formik.errors.email}*
                              </p>
                            ) : null}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <div className="input-group auth-pass-inputgroup">
                          <input
                           onChange={formik.handleChange}
                           name="password"
                            type={Hide? "text":"password"}
                            className="form-control"
                            placeholder="Enter password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                            value={formik.values.password}
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline" onClick={()=>setHide(pre=>!pre)} ></i>
                          </button>

                        </div>
                        {formik.errors.password ? (
                              <p style={{ color: "red" }}>
                                {formik.errors.password}*
                              </p>
                            ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-check"
                        />
                        <label
                          className="form-check-label"
                          for="remember-check"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button 
                           type="submit"
                          className="w-100 custom btn btn-primary w-100 waves-effect waves-light"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="mt-4 mt-md-5 text-center">
                  <p className="mb-0">
                    © <script>document.write(new Date().getFullYear())</script>{" "}
                    Muslim Matcher. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by Codobux
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end col --> */}
      </div>
      {/* <!-- end row --> */}
    </div>
  );
};

export default Login;
