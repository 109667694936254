import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { MenuFoldOutlined,MenuUnfoldOutlined } from '@ant-design/icons';
import { Button } from 'antd';
const Header = ({setMenuTab,menuTab}) => {
    const navigate = useNavigate();
    const HandleLogout = () => {
        window.sessionStorage.removeItem("token");
        navigate("/");
      };
  return (
    <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <!-- LOGO --> */}
            <div className="navbar-brand-box">
              <a href="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src={require("../../assets/images/logo-dark-png.png")}
                    alt=""
                    height="70"
                  /> 
            {
                menuTab==false? <Button onClick={()=>setMenuTab(true)}><MenuFoldOutlined /></Button>:
                <Button onClick={()=>setMenuTab(false)}><MenuUnfoldOutlined /></Button>
            }
                </span>
                <span className="logo-lg">
                  <img
                    src={require("../../assets/images/logo-dark-png.png")}
                    alt=""
                    height="60"
                  />
                </span>
              </a>

              <a href="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={require("../../assets/images/logo-dark-png.png")}
                    alt=""
                    height="50"
                  />
                </span>
                <span className="logo-lg mt-2">
                  <img
                    src={require("../../assets/images/logo-dark-png.png")}
                    alt=""
                    height="80"
                  />
                </span>
              </a>
            </div>

            {/* <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button> */}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div> */}

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={require("../../assets/images/user.png")}
                  alt=""
                />
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  Admin
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <!-- item--> */}
                <button
                  className="dropdown-item text-danger"
                  onClick={() => HandleLogout()}
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                  <span key="t-logout">Logout</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}

export default Header