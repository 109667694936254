import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ menuTab }) => {
  return (
    <div className={menuTab ? "vertical-menu menu-click" : "vertical-menu"}>
      <div data-simplebar="" className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
          <div className="flex-colum-part mm-active">
            <li className="menu-title" key="t-menu">
              Menu
            </li>
            <li>
              <NavLink
                to="/admin/user-management"
              >
                <i className="bx bx-user"></i>
                <span key="t-dashboards">User Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/selfi_verification" className="waves-effect">
                <i className="bx bx-user"></i>
                <span key="t-dashboards">Selfie Verification</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/report" className="waves-effect">
                <i className="bx bx-user"></i>
                <span key="t-dashboards">Report</span>
              </NavLink>
            </li>
            </div>
          </ul>
        </div>
        {/* <!-- Sidebar --> */}
      </div>
    </div>
  );
};

export default Sidebar;
