import { UserManagement } from './Admin/pages/dashboard/UserManagement';

import Login from './Admin/pages/login/Login';
import './App.css';
import{ BrowserRouter ,Routes,Route, Navigate }from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ProfileView from './Admin/pages/profile_view/ProfileView';
import Selfi_Verification from './Admin/pages/Selfi_verification/Selfi_Verification';
import Report from './Admin/pages/report/Report';
import PublicProfileView from './Admin/pages/profile_view/PublicProfileView';

function App() {
  var authentication = localStorage.getItem('accessToken');
  return (
    <div className="App">
    
   <BrowserRouter>
   <Routes>
   { !authentication ?<Route  path="*" element={<Navigate to="/" />    }  /> :<Route path='*'    element={<Navigate to="/admin/user-management" replace />}  replace />}




    <Route path='/' element={<Login />} />
    <Route path='admin/user-management' element={ <UserManagement />} />
    <Route path='/profile-view/:id' element={ <ProfileView />} />
    <Route path='/view-profile/:id' element={ <PublicProfileView />} />
    <Route path='admin/selfi_verification' element={<Selfi_Verification />} />
    <Route path='admin/report' element={<Report />} />
   </Routes> 
   <ToastContainer autoClose={2000} />
   </BrowserRouter>
    </div>
  );
}

export default App;
