import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserList, SelfieApproved } from '../../../Redux/Actions/auth';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Layout from '../../components/Layout';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/loading/Loading';

export default function Selfi_Verification() {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [reasone, setReasone] = useState("");
  const [setobject, setSetobject] = useState("");
  const [loading, setLoading] = useState(false);

  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  const userlist = useSelector((state) => state?.adminSlice?.userList);
  const nevigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getUserList());
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);
  const update_status = (id, status) => {
    dispatch(SelfieApproved({
      id: id,
      status: status,
      reasone: reasone
    })).then(function (data) {
      dispatch(getUserList());
      setShow(false)
      toast.success(data.status == 200 ? data.message : data.message)
    })
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      {loading && <Loading />}
      <Layout>
        <div id="layout-wrapper">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Selfie Verification</h4>
                        <div className="d-flex justify-content-between">
                          <label className="sort-drop">


                          </label>

                          <label className="search-filter">
                            Search:{" "}
                            <input
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              name="search"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="datatable"
                            />
                          </label>
                        </div>
                        <div className="table-responsive custom_scroll">
                          <table
                            id="datatable"
                            className="table table-bordered dt-responsive  nowrap w-100"
                          >

                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Country</th>
                                <th>status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {userlist
                                ?.filter((item) => {
                                  const searchTerm = search.toLowerCase();
                                  return (
                                    item?.first_name?.toLowerCase().includes(searchTerm) ||
                                    item?.email?.toLowerCase().includes(searchTerm) ||
                                    (item?.country_code + " " + item?.phone_number)?.includes(searchTerm)
                                  );
                                })
                                .map((item, i) => {
                                  if (item?.selfie_approved != 1) {


                                    return (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          <div className="img-td">
                                            <img
                                              src={
                                                HOST_NAME +
                                                item?.selfie_pic
                                              }
                                              alt=""
                                            />
                                          </div>
                                        </td>
                                        <td
                                          onClick={() => {
                                            nevigate(`/profile-view/${item?._id}`);
                                          }}
                                        >
                                          <div
                                            className="user-name"
                                            data-bs-toggle="modal"
                                            data-bs-target=".bs-example-modal-lg"
                                          >
                                            {item?.first_name}
                                          </div>
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>{item?.country_code + " " + item?.phone_number}</td>
                                        <td>{item?.country}</td>
                                        <td className="yellow_text green_text">{item?.selfie_approved == 0 ? "Pending Approval" : item?.selfie_approved == 2 ? "Rejected" : "Approved"}</td>
                                        <td>
                                          <button
                                            onClick={() => {
                                              update_status(item?._id, 1)
                                            }}
                                            className="btn btn-success btn-sm ml-3 mr-4"
                                          >
                                            Accept
                                          </button>
                                          <button className="btn btn-danger btn-sm mr-6" onClick={() => {
                                            setSetobject(item?._id)
                                            handleShow()
                                          }}>
                                            Reject
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Reasone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Write Reason</Form.Label>
                <Form.Control


                  as="textarea" rows={3}
                  onChange={(e) => {
                    setReasone(e.target.value)
                  }}
                />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => {
              update_status(setobject, 2)
            }}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  )
}
