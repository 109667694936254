// Modify your Loading component to add/remove 'loading' class to the body element

import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styles from './loading.module.css';

const Loading = () => {
  useEffect(() => {
    // Add 'loading' class to body when the component mounts
    document.body.classList.add('loading');

    // Remove 'loading' class from body when the component unmounts
    return () => {
      document.body.classList.remove('loading');
    };
  }, []);

  return (
    <div className={styles.loadingContainer}>
      <Spinner animation="border" role="status" className={styles.loadingSpinner}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loading;