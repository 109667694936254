import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReport,
  reportDelete,
  reportList,
} from "../../../Redux/Actions/auth";
import { Modal } from "react-bootstrap";

const Report = () => {
  const [search, setSearch] = useState("");
  const [show_description, setShow_description] = useState(false);
  const [description, setdescription] = useState();
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state?.adminSlice?.report_list);
  console.log(reportData);
  useEffect(() => {
    dispatch(reportList());
  }, []);
  const handleDiscription=(val)=>{
    setShow_description(true)
    setdescription(val)
  }
  return (
    <Layout>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Report</h4>
                      <div className="d-flex justify-content-between">
                        <label className="sort-drop"></label>

                        <label className="search-filter">
                          Search:{" "}
                          <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            name="search"
                            class="form-control form-control-sm"
                            placeholder=""
                            aria-controls="datatable"
                          />
                        </label>
                      </div>
                      <div className="row layout-top-spacing">
                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                          <div className="widget-content widget-content-area br-8">
                            <div
                              id="zero-config_filter"
                              className="dataTables_filter searchbar "
                            ></div>
                            <div className="table-responsive custom_scroll">
                              <table
                                id="zero-config"
                                className="table table-bordered dt-responsive  nowrap w-100"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Report by</th>
                                    <th>Report to</th>
                                    <th>Reasone</th>
                                    <th>Description</th>
                                    {/* <th>Status</th> */}
                                    {/* <th>Action</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {reportData
                                    ?.filter((data) =>
                                      data?.reason
                                        ?.toLowerCase()
                                        .includes(search)
                                    )
                                    ?.map((item, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>
                                            {item?.sender_id?.first_name +
                                              " " +
                                              item?.sender_id?.last_name}
                                          </td>

                                          <td>
                                            {item?.receiver_id?.first_name +
                                              " " +
                                              item?.receiver_id?.last_name}
                                          </td>
                                          <td

                                       
                                          >
                                            <p className="line_break">
                                              {item?.reason}
                                            </p>
                                          </td>
                                          <td className="fix-td"
                                            onClick={() => {
                                              // setShow_description(true);
                                              // setDescription_info(item?.description);
                                            }}
                                          >
                                           <div className="height-fix-des">
                                           <p className="line_break description-td">
                                              {item?.description}
                                            </p>
                                            <div onClick={()=> handleDiscription(item?.description)} className="read-more-text">Read More</div>
                                           </div>
                                          </td>
                                     

                                          <td>
                                            <div className="">
                                              {item?.closed == 1 ? (
                                                <button
                                                  onClick={() => {
                                                    dispatch(
                                                      closeReport(item?._id)
                                                    );
                                                  }}
                                                  className="btn btn-sm mr-4 btn-dark custom_p_btn green_text"
                                                >
                                                  Close the Action
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn btn-sm mr-4 btn-dark custom_p_btn green_text"
                                                  disabled
                                                >
                                                  Closed
                                                </button>
                                              )}
                                              <button
                                                onClick={() =>
                                                  dispatch(
                                                    reportDelete(item?._id)
                                                  )
                                                }
                                                className="btn btn-danger btn-sm ml-3 mr-4"
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </td>
                                         
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- BREADCRUMB --> */}

          {/* <div className="paginating-container pagination-solid">
      <ReactPaginate
        activeClassName="active"
        nextLabel="next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="Prev"
        renderOnZeroPageCount={null}
        className="pagination"
      />
    </div> */}
        </div>
      </div>
      <Modal show={show_description} className="des-popup">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Description
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow_description(false)}
            >          
            </button>
          </div>

          <div className="modal-body">
            <p>
              {description}
            </p>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Report;
