import {configureStore} from "@reduxjs/toolkit"
import adminAuthReducer from "./Reducers/AuthSlice"
import AdminSlice from "./Reducers/AdminSlice"
export const Store = configureStore( {
    reducer: {
        adminAuth:adminAuthReducer,
        adminSlice:AdminSlice
    },
})


// Link : https://admin.muslimmatcher.co.uk/
//  email : admin@gmail.com password : 123456