import { createSlice } from "@reduxjs/toolkit";
import { closeReport, getUserList, profileDetail, reportDelete, reportList, updatestatusAction } from "../Actions/auth";
import { toast } from "react-toastify";
const initialState={
    loading: false,
    success: false,
    error: false,
    loggedInStatus: false,
    userList:[],
    profile:[],
    report_list:null
}
 
const adminSlice=createSlice({
    name:"adminSlice",
    initialState,
    reducers:{ },
    extraReducers:(any)=>{
        any
        .addCase(getUserList.pending, (state) => {
            state.loading = true;
          })
          .addCase(getUserList.fulfilled, (state, { payload }) => { 
            state.loading = false;
            state.success = true;
            state.data = payload.accessToken;
            
            if (payload?.data) {
              state.userList=payload.data
            }
            if (!payload.data) {
              console.log("error2");
               
            }
          })
          .addCase(profileDetail.fulfilled, (state, { payload }) => { 
            state.loading = false;
            state.success = true;
            if (payload?.data) {
              state.profile=payload.data
            }
            if (!payload.data) {
              console.log("error2");
               
            }
          })
          .addCase(reportList.fulfilled, (state, { payload }) => { 
            state.loading = false;
            state.success = true;
            state.data = payload.accessToken;
            
            if (payload?.data) {
              state.report_list=payload.data
            }
            if (!payload.data) {
              console.log("error2");
               
            }
          })
          .addCase(closeReport.fulfilled, (state, { payload }) => {
            if (payload?.status==200) {
              const newArr = state.report_list.map(
                (report_list) => report_list._id == payload.data._id?payload.data:report_list
              );
              state.report_list = newArr;
              toast.success(payload.message);
            }
            if (!payload.status==200) {
              toast.error(payload.message);
            }
          })
          .addCase(reportDelete.fulfilled, (state, { payload }) => {
            if (payload?.status==200) {
              const newArr = state.report_list.filter(
                (report_list) => report_list._id != payload?.data
              );
              state.report_list = newArr;

              toast.success(payload.message);
            }
            if (!payload.status==200) {
              toast.error(payload.message);
            }
          }) .addCase(updatestatusAction.fulfilled, (state, { payload }) => {
            if (payload?.status==200) {
             
           


              const newArr = state?.userList.map(user => {
                if (user._id === payload.data._id) {
                  return payload.data; // Update the user with new data
                }
                return user; // Keep the unchanged user as is
              });

             

             
               state.userList = newArr;

              
            }
            if (!payload.status==200) {
              toast.error(payload.message);
            }
          })
    }
})
export default adminSlice.reducer