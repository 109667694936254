import axios from "axios";

const Api = axios.create({
  baseURL:process.env.REACT_APP_BASE_URL,
  // headers: {
  //   authorization: window.sessionStorage.getItem("token")
  // },
})
Api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error.response;
    }
  );


export default Api