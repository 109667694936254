import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "../Actions/auth";
import { toast } from "react-toastify";
const initialState={
    loading: false,
    success: false,
    error: false,
    loggedInStatus: false,
}
export const toastSuccess = (err) => {
  toast.success(err, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
  });
};
export const toastfunc = (err) => {
  toast.error(err, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
 
const adminAuthSlice=createSlice({
    name:"adminAuth",
    initialState,
    reducers:{
        keepMeloggedIn:(state,{payload})=>{
            state.loggedInStatus = payload;
        }
    },
    extraReducers:(any)=>{
        any
        .addCase(adminLogin.pending, (state) => {
            state.loading = true;
          })
          .addCase(adminLogin.fulfilled, (state, { payload }) => { 
            state.loading = false;
            state.success = true;
            state.data = payload.accessToken;
            
            if (payload.data) {
             sessionStorage.setItem("token", payload.data.accessToken); 
             window.location.href = "/admin/user-management";  
            //  toastSuccess(payload.message)
            }
            // if (payload?.data && state.loggedInStatus) {
            //   console.log("hhhhh");
            //     // console.log(payload?.success,"success");
            //   sessionStorage.setItem("token", payload.data.accessToken);
            //   // window.location.href = "/user-management";

            // }
            if (!payload.data) {
              toastfunc(payload?.message);
              console.log("error2");
               
            }
          })
          .addCase(adminLogin.rejected, (state) => {
            state.loading = false;
            state.error = true;
          })

    }
})
export default adminAuthSlice.reducer