import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6">
          <script>document.write(new Date().getFullYear())</script> ©
          Muslim Matcher.
        </div>
        <div className="col-sm-6">
          <div className="text-sm-end d-none d-sm-block">
            Design & Develop by Codobux
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer