import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = ({ children }) => {
    const [menuTab,setMenuTab]=useState(false) 
  return (
    <div>
      <Header setMenuTab={setMenuTab} menuTab={menuTab}/>
      <Sidebar menuTab={menuTab}/>
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
